#edit-item {
    width : 100px;
    margin-left: 15px;
}

.ui.modal {
    width: 60%;
}

.react-datepicker-popper {
    z-index: 1001;
}

div .header {
    text-align: center;
}

#edit-modal-actions {
    display: flex;
    justify-content: center;
}

body.dimmed.dimmable {
    overflow-y: scroll !important;
}

@media (max-width: 425px) {
    #edit-item {
        width : 100px;
        margin-left: 0;
    }
}
