.ui.grid > .row {
  margin: 0;
  padding-bottom: 0;
}

.ui.grid > .row > .column {
  margin-top: 0;
  padding-left: 80px;
  padding-right: 80px;
}

#admin-filter-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

#table {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 840px) {
  #settings {
    margin-top: 0px;
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 768px) {
  main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ui.grid > .row > .column {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 425px) {
  #add-mobile {
    display: block;
    margin-bottom: 20px;
  }

  #add-desktop {
    display: none;
  }

  #bulkarchive-mobile {
    display: block;
    margin-bottom: 20px;
  }

  #bulkarchive-desktop {
    display: none;
  }
}
