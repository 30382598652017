table.ui.table.lf_table {
    scroll-behavior: auto;
}  

.ui.sortable.table .unclickable {
    cursor: default;
}

.ui.sortable.table .unclickable:hover {
    background-color: #f9fafb;
}

.table-buttons {
    display: flex;
    justify-content: space-between;
}