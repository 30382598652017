#login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 95vh;
}

#login-box {
  min-width: 350px;
}

#login-button {
  margin-top: 10px;
}

#login-message {
  margin-top: 30px;
  width: 50%;
}

@media (max-width: 425px) {
  #admin-login {
    width: 300px;
  }

  #login-message {
    width: 300px;
  }
}