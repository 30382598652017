#description {
  text-align: left;
  font-size: 18px;
  font-family: "Lato";
  margin: auto;
  margin-bottom: 30px;
  max-width: 80%;
  padding-top: 0ch;
}

#description p {
  line-height: 1.25em;
}

.location {
  font-size: 16px;
  margin-left: 5%;
}

#cards-widget {
  display: none;
}

#faq-message {
  text-align: left;
}

#faq-message .header {
  text-align: left;
}

#cards-widget {
  display: block;
  justify-content: flex-start;
}

#found-item-modal {
  display: none;
}

#admin-filter-bar {
  width: 100%;
  position: sticky;
  top: 0;
  padding-top: 20px;
  z-index: 500;
}

@media (max-width: 840px) {
  #description {
    max-width: 100%;
    padding: 20px;
  }
}