#search div.ui.icon.input {
  max-width: 400px;
  width: 100%;
  margin-bottom: 15px;
}

#searchbar {
  border-radius: 0.28571429rem;
  border: 2px solid #c2c1c1;
  width: 400px;
}

@media (max-width: 1180px) {
  #search div.ui.icon.input {
    max-width: none;
    width: 100%;
    font-size: 1.2em;
    margin-bottom: 15px;
  }
}
