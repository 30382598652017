.ui.button {
    margin-right: 0;
    margin-bottom: 0.78571429em;
}

.ui.modal {
    width: 60%;
}

.react-datepicker-popper {
    z-index: 1001;
}

div .header {
    text-align: center;
}

#modal-actions {
    display: flex;
    justify-content: right;
}

body.dimmed.dimmable {
    overflow-y: scroll !important;
}
