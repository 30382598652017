.ui.cards {
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.card-wrapper {
  display: grid;
  height: max-height;
  gap: 10px;
}

.card-wrapper-outer {
  height: max-height;
}

.ui.card > .top-content {
  padding: 30px;
}

.ui.card > .bottom-content {
  text-align: left;
  padding: 30px;
}

.ui.card.item-card {
  overflow: hidden;
  width: 100%;
}

.ui.card.header {
  text-align: center;
}

.ui.card::after {
  content: "";
}

.ui.card.item-card.top-content {
  padding-top: 40px;
}

@media (max-width: 480px) {
  .card-wrapper {
    min-width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .card-wrapper {
    min-width: calc(50% - 10px);
    height: max-content;
  }
}

@media (min-width: 1025px) and (max-width: 1180px) {
  .card-wrapper {
    min-width: calc(33.33% - 20px);
  }
}

@media (min-width: 1180px) {
  .card-wrapper {
    min-width: calc(25% - 20px);
  }
}

img.card-img {
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: none;
  height: 500px;
}

.ui.button.image-button {
  margin-top: 15px;
}

.show-image {
  float: right;
  margin: -15px !important;
}
